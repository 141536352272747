@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .input-field {
    @apply bg-[#eeeeee] rounded-md px-3 py-2 mr-2 w-full;
  }

  .my-form-control {
    background-color: rgba(136, 146, 165, 0.23) !important;
    border-radius: 0.5rem;
  }
  table {
    @apply text-sm;
  }
}

.login .MuiInput-underline,
.my-form-control .MuiInput-underline {
  padding-inline: 0.25rem 0.5rem !important;
  padding: 0.5rem !important;
}
.login .MuiInput-underline::before,
.my-form-control .MuiInput-underline::before,
.my-form-control .MuiInput-underline:focus {
  border-bottom: none !important;
}

table,
td,
th {
  border: 1px solid black;
  padding: 2px;
}

td {
  max-height: 20px;
  height: 20px;
}

.upper-left-table tr td:first-child {
  width: 115px !important;
}

@media print {
  .print-button > button {
    display: none !important;
  }
}
